.blog-viewer {
 article {
  margin-bottom: 30px;
  h2 {
   font-size: 25px;
   margin-bottom: 5px;
  }
  time {
   display: block;
   margin-bottom: 5px;
  }
 }
}
