header {
  position: relative;
  nav {
    background: $cl-black url('../../images/art_nav_bkgd.gif') no-repeat scroll center -4px;
    height: 44px;
    margin: 0;
    overflow: hidden;
    padding: 0px;
    text-align: center;
    img {
      display: none;
      height: auto;
      width: 70px;
      @media only screen and (max-width: $breakpoint-sm) {
        display: block;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
      }
    }
    ul.social-media-icons {
        li {
          height: 28px;
          @media only screen and (max-width: $breakpoint-sm) {
            height: 20px;
          }
          svg {
            height: 28px;
            @media only screen and (max-width: $breakpoint-sm) {
              fill: $cl-black;
              height: 20px;
              margin-right: 0.7rem;
            }
          }
        }
        @media only screen and (max-width: $breakpoint-sm) {
          right: 10px;
          position: absolute;
          top: 2px;
        }
    }
    .nav-trigger {
      display: none;
      fill: $cl-white;
      height: 20px;
      position: absolute;
      left: 10px;
      stroke: $cl-white;
      top: 14px;
      width: 20px;
      z-index: 1;
      @media only screen and (max-width: $breakpoint-sm) {
        display: block;
      }
    }
    ul {
      display: inline-block;
      list-style: none;
      margin: 0px;
      padding: 0px;
      li {
        display: inline-block;
        height: 27px;
        margin-top: 9px;
        position: relative;
        vertical-align: top;
        width: auto;
        a {
          background: transparent;
          color: $cl-page-hdr-fill;
          display: inline-block;
          font-family: birch-std, serif;
          font-size: 30px;
          font-weight: 400;
          letter-spacing: 7px;
          line-height: 30px;
          position: relative;
          text-decoration: none;
          text-transform: uppercase;
          z-index: 0;
          &.active {
            color: $cl-nav-active-fill;
          }
          &:hover {
            color: $cl-nav-active-fill;
            text-decoration: none;
          }
          &:before {
            content: attr(data-text);
            position: absolute;
            -webkit-text-stroke: 0.13em $cl-page-hdr-stroke; /* Chris Coyier's favorite color! */
            left: 0;
            z-index: -1;
          }
          @media only screen and (max-width: $breakpoint-md) {
            font-size: 26px;
            letter-spacing: 6px;
          }
        }

      }
    }
    ul.nav-items {
      margin-right: 15px;
      &.hidden {
          @media only screen and (max-width: $breakpoint-sm) {
            display: none;
          }
      }
      li {
        padding-left: 28px;
        a {
          margin-right: 6px;
        }
        &:before {
          background: transparent url('../../images/art_nav_sep.gif') no-repeat scroll left top;
          content: "";
          height: 16px;
          left: 0px;
          margin: 0px 14px 0px 0px;
          position: absolute;
          top: 5px;
          width: 16px;
        }
        @media only screen and (max-width: $breakpoint-sm) {
            display: block;
            margin: 25px 0;
        }
      }
      @media only screen and (max-width: $breakpoint-sm) {
        background-color: $cl-black;
        margin: 0;
        padding-bottom: 20px;
        width: 70%;
        position: absolute;
        padding-left: 10px;
        top: 47px;
      }
    }

    @media only screen and (max-width: $breakpoint-sm) {
      background: none;
      height: auto;
      text-align: left;
    }
  }
  @media only screen and (max-width: $breakpoint-sm) {
      position: fixed;
      width: 100%;
      z-index: 1;
  }

}
