/**
 * $Filename: mixins.scss
 * $Author: Michael Kaplan
 */

/* Sample Usage:    @include fontRender(#5a5b5d; normal; ~'HelveticaNeueLT75Bold'; 0.6875rem; 1.1rem; 0.075rem);    */
@mixin fontRender($color, $weight: 400, $nameString: null, $size: 1rem, $lineHeight: 1.0rem, $letterSpacing: normal){
    color: $color;
    font-weight: $weight;
    font-family: $nameString;
    font-size: $size;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
}

@mixin stroke($color: $cl-black, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

    /* prepare the selectors to add a stroke to */
  .stroke-single {
    position: relative;
    background: transparent;
    z-index: 0;
  }
  /* add a single stroke */
  .stroke-single:before {
    content: attr(data-text);
    position: absolute;
    -webkit-text-stroke: 0.13em $cl-page-hdr-stroke; /* Chris Coyier's favorite color! */
    left: 0;
    z-index: -1;
  }

@mixin MIXCSSTransform($prop: scale, $x: 1, $y: 1, $originX: 0, $originY: 0){
  -webkit-transform: $prop +'('+ $x +', ' + $y +')';
  -moz-transform: $prop +'('+ $x +', ' + $y +')';
  -o-transform: $prop +'('+ $x +', ' + $y +')';
  -ms-transform: $prop +'('+ $x +', ' + $y +')';
  transform: $prop +'('+ $x +', ' + $y +')';
  transform-origin: $originX $originY;
}

// Micro ClearFix Mixin
@mixin cf(){
  zoom: 1;

  &:before, &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
