footer {
  margin: 100px auto 0px auto;
  padding-bottom: 40px;
  text-align: center;
  nav {
    background: $cl-black url('../../images/art_nav_bkgd.gif') no-repeat center -4px;
    height: 44px;
    margin-bottom: 10px;
    overflow: hidden;
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      li {
        display: inline-block;
        height: 27px;
        margin-right: 20px;
        margin-top: 12px;
        position: relative;
        vertical-align: top;
        width: auto;
        a {
          background: transparent;
          color: $cl-page-hdr-fill;
          display: inline-block;
          font-family: birch-std, serif;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 5px;
          line-height: 20px;
          position: relative;
          text-decoration: none;
          text-transform: uppercase;
          z-index: 0;
          &.active {
            color: $cl-nav-active-fill;
          }
          &:hover {
            color: $cl-nav-active-fill;
            text-decoration: none;
          }
          &:before {
            content: attr(data-text);
            position: absolute;
            -webkit-text-stroke: 0.13em $cl-page-hdr-stroke;
            left: 0;
            z-index: -1;
          }
          @media only screen and (max-width: $breakpoint-xs) {
            font-size: 15px;
            letter-spacing: 4px;
          }
        }
        &:last-child {
          margin-right: 0px;
        }
        @media only screen and (max-width: $breakpoint-xs) {
          margin-right: 11px;
        }
      }
    }
  }

}
