.comp-bio-modal {
  @include fontRender($cl-white, 400, 'Open Sans', 14px, 20px, normal);
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  text-align: left;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.hidden {
    display: none;
  }
  .modal-wrapper {
    border: 10px solid rgba($cl-gray,0.8);
    border-radius: 5px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 500px;
    z-index: 1;
    @media only screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }
  .modal-content {
    background-color: $cl-black;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    figure {
      margin: 0;
      figcaption {
        h3 {
          margin-bottom: 0;
        }
        em {
          display: block;
          margin-bottom: 10px;
        }
      }
    }

  }
  .modal-bkgd {

  }
}
