.member-card {
    background-color: $cl-shark;
    border-radius: 7px;
    cursor: pointer;
    display: inline-block;
    @include fontRender($cl-white, 400, 'Open Sans', 13px, 15px, normal);
    min-height: 178px;
    margin: 0px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 100%;
    &.no-bio {
      pointer-events: none;
    }
    figcaption {
      strong {
          color: $cl-page-hdr-fill;
          display: block;
          margin-bottom: 5px;
          @media only screen and (max-width: $breakpoint-xs) {
              font-size: 18px;
              margin-bottom: 10px;
          }
      }
      em {
          display: block;
      }
      @media only screen and (max-width: $breakpoint-xs) {
          display: inline-block;
          padding-left: 15px;
          vertical-align: top;
          width: 75%;
    	}
    }
    img {
        -webkit-filter: sepia(1);
        border: none;
        clear: right;
        display: inline-block;
        filter: sepia(1);
        margin-bottom: 5px;
        position: relative;
        width: 100px;
        height: 100px;
        @media only screen and (max-width: $breakpoint-xs) {
            height: 25%;
            margin: 0;
            width: 25%;
      	}
    }
    &:hover:after {
      animation: shine 3s ease-out;
      //animation-fill-mode: forwards;
      content: "";
      pointer-events: none;
      position: absolute;
      top: -110%;
      left: -210%;
      width: 200%;
      height: 200%;
      opacity: 0;
      transform: rotate(30deg);

      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
      );
    }
    @media only screen and (max-width: $breakpoint-xs) {
        min-height: 0;
        text-align: left;
    }
}
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
